body {
  margin: 0;
  font-family: 'Source Sans Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media print {
  body {
    visibility: hidden;
  }

  #aircraft-details-right-panel {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    border-left: none;
    overflow: visible;
  }

  #expand-more-icon {
    display: none;
  }
}
